import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const pageTransitionsForNextQuery = graphql`
  query PageTransitionsForNextQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageTransitionsForNextQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`Page transitions are cool and can add that extra layer of polish to your web app.`}</p>
    <p>{`In this post, I'll explain how to add a very basic page transition to your `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{` app. We are not going to do anything super fancy. The page transition that we're going to use is based on spring animations that generally produces more believable animations since they behave similarly to objects in the physical world.`}</p>
    <h2>{`Next.js setup`}</h2>
    <p>{`I am going to assume you already have a Next.js app. If not, go ahead and `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/getting-started"
      }}>{`set one up`}</a>{` now.`}</p>
    <p>{`The easiest is to run the following command from your terminal:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`yarn`}</span>{` create next-app`}</code></pre></div>
    <p>{`You are going to need a `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/advanced-features/custom-app"
      }}>{`custom Next.js App Component`}</a>{`. If you don't have one already, inside you `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pages`}</code>{` directory add a new file called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_app.tsx`}</code>{` (or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`_app.js`}</code>{` if you're using JavaScript).`}</p>
    <p>{`Add the following React component as a starting point:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AppProps `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'next/app'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CustomApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Component`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` pageProps `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` AppProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSX`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Element `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`pageProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` CustomApp`}</code></pre></div>
    <p>{`This custom App does nothing more than what Next.js does out of the box. For each page in your app, it will be called with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Component`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pageProps`}</code>{` props, which is the specific page component and the initial props object passed to it via one of Next.js' page `}<a parentName="p" {...{
        "href": "https://nextjs.org/docs/basic-features/data-fetching"
      }}>{`data fetching methods`}</a>{`, like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getStaticProps`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getServerSideProps`}</code>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The Next.js Custom App component is the perfect place to add any components and other code that will be used for every page of your app. Things like `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`ThemeProvider`}</code>{`s and global style resets. We will leave those out to keep this example focused on the page transition.`}</p>
    </blockquote>
    <h3>{`react-flip-toolkit`}</h3>
    <p>{`We will make use of the `}<a parentName="p" {...{
        "href": "https://github.com/aholachek/react-flip-toolkit"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-flip-toolkit`}</code></a>{` library to apply the spring-based animation between our pages. This library is based on Paul Lewis's `}<a parentName="p" {...{
        "href": "https://aerotwist.com/blog/flip-your-animations/"
      }}>{`FLIP animations`}</a>{` idea. It is worth reading Paul's post if you haven't already. The FLIP concept can be applied to so many different animation problems.`}</p>
    <p>{`We are mostly interested in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipper`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` components that the library exports for us. Let's take a look at those.`}</p>
    <h4>{`Flipper`}</h4>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipper`}</code>{` component wraps around all the child components that will form part of the animation. You can think of it as a React Context Provider. This component takes one mandatory prop, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipKey`}</code>{`, which it uses to determine when the children should be animated. When the key changes, an animation triggers.`}</p>
    <p>{`Because we want to trigger our transition animation every time the route changes, it naturally makes sense that we use the page's route as the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipKey`}</code>{`. Lucky for us, the Next.js custom app component also receives a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`router`}</code>{` prop. Let's add this to our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CustomApp`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AppProps `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'next/app'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Flipper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Flipped `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-flip-toolkit'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CustomApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  Component`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  pageProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Added this prop`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` AppProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSX`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Element `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Wrapping our page \`Component\` with the \`Flipper\``}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Flipper flipKey`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`asPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`pageProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Flipper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` CustomApp`}</code></pre></div>
    <p>{`You might have noticed that we used the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asPath`}</code>{` attribute of the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`router`}</code>{` object and not `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pathname`}</code>{`. That is because if we are using dynamic routes, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pathname`}</code>{` will be the same for pages matching the same dynamic route. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`asPath`}</code>{`, on the other hand, is the actual path, including the query params.`}</p>
    <h4>{`Flipped`}</h4>
    <p>{`At this stage, our page transition is still not working, and that is because we haven't told the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipper`}</code>{` which child components should be animated. That is where the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` component comes in.`}</p>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` component wraps each animatable component inside the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipper`}</code>{` parent component. To identify each individual animatable component, a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipId`}</code>{` prop is required for the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` component. Each component that has the same `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipId`}</code>{` will be animated from its previous state to the new state when the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipper`}</code>{` is updated. This is very useful if you want to animate a list of items that get reordered.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Flipped`}</code>{` component does not necessarily have to be a direct child of `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`Flipper`}</code>{`. It can be deeply nested.`}</p>
    </blockquote>
    <p>{`In our case, we don't have a list of items to animate; we want to animate the content of one page into the next. We can, therefore, use the same `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipId`}</code>{` for all our pages. Let's be extremely creative and call this `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flipId`}</code>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`page`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AppProps `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'next/app'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Flipper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Flipped `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-flip-toolkit'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CustomApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Component`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` pageProps`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` router `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` AppProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSX`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Element `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Flipper flipKey`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`asPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Wrapping our page \`Component\` with a \`Flipped\` component`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Flipped flipId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"page"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`pageProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Flipped`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Flipper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` CustomApp`}</code></pre></div>
    <p>{`Depending on how your app's page components use additional props, the page transition might or might not work already. The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` component will pass a couple of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flippedProps`}</code>{` down to its direct child component, i.e. the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Component {...pageProps} />`}</code>{` component. These props are used to set the necessary opacity, translate and scale values on the underlying DOM elements. For this reason, these `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flippedProps`}</code>{` need to be spread into a native DOM component ultimately, like a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<span>`}</code>{` and not into a custom React component, like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<CustomComponent>`}</code>{`.`}</p>
    <p>{`The easiest to do this, is just to wrap the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Component {...pageProps} />`}</code>{` component in our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`CustomApp`}</code>{` with another `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<div>`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` AppProps `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'next/app'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` Flipper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Flipped `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'react-flip-toolkit'`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`CustomApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` Component`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` pageProps`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` router `}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` AppProps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`JSX`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Element `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Flipper flipKey`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`asPath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Flipped flipId`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"page"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Wrapping our page \`Component\` with a native \`div\``}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
          `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Component `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`pageProps`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
        `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`div`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
      `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Flipped`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Flipper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`export`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`default`}</span>{` CustomApp`}</code></pre></div>
    <p>{`With this change, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Flipped`}</code>{` component is always able to apply the necessary animation props to the underlying `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`div`}</code>{`. The only outstanding part that might stop your page transition from working is if your app is not using client-side routing.`}</p>
    <blockquote>
      <p parentName="blockquote">{`I am not going to say page transitions are impossible if you're using server-side routing, i.e. page refreshes, because people always do amazing things with the web that I thought was impossible. I will say that it would probably not be worth the effort.`}</p>
    </blockquote>
    <p>{`Luckily for us, Next.js makes client-side routing very easy and will default to that if we use their `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Link`}</code>{` component correctly and our pages do not use `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getServerSideProps`}</code>{` as the data fetching method. As the name suggests, that will always happen server-side.`}</p>
    <h3>{`Client-side Routing`}</h3>
    <p>{`The recommended declarative way to route within your Next.js app is to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<Link>`}</code>{` component available through the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`next/link`}</code>{` package: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`import Link from 'next/link'`}</code></p>
    <p>{`A link to your "About" page could look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Link href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/about"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`About`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Link`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`This will work fine if you have an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`about.tsx`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`about.js`}</code>{` file in your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`pages`}</code>{` directory and that component doesn't rely on server-side data fetching for each page render.`}</p>
    <p>{`If you have dynamic page routes, it is slightly different. Let's say you have the following pages:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`pages /
  articles /
    [article].tsx`}</code></pre></div>
    <p>{`If you want to create a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Link`}</code>{` to an individual article page, it would have to look something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`Link href`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/articles/[article]"`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/articles/the-article-slug"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`Read more`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`a`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`Link`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span></code></pre></div>
    <p>{`If you forget to add the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`as`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`href`}</code>{` prop, the page will always perform a refresh and load server-side. Make sure to use this correctly throughout your app.`}</p>
    <p>{`Sometimes you want to route programmatically within a callback or event handler. For that, you can use the Next.js `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`router`}</code>{` directly. If you're using a React function component, the easiest is to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`useRouter`}</code>{` custom hook: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`import { useRouter } from 'next/router'`}</code>{`.`}</p>
    <p>{`For our previous page examples, you would be able to route like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`push`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'/about'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Or for the dynamic article route:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}>{`router`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`push`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'/articles/[article]'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'/articles/the-article-slug'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  shallow`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Note, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`shallow: true`}</code>{` option. That is necessary to force client-side-only routing.`}</p>
    <p>{`With these things in place, your page transitions should be working. If for some reason it isn't, check here for `}<a parentName="p" {...{
        "href": "https://github.com/aholachek/react-flip-toolkit#troubleshooting"
      }}>{`ideas to troubleshoot`}</a>{`.`}</p>
    <hr />
    <p>{`Check out this `}<a parentName="p" {...{
        "href": "https://github.com/jperasmus/nextjs-page-transition-example"
      }}>{`example repo`}</a>{` if you want to see it in action.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      